import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link } from 'gatsby';
import CtaFooter from '../components/cta-footer';

function Preis() {
  return (
    <Layout>
      <SEO
        title="Ihre Investition"
        description="Die attraktive Komplettlösung für Ihren professionellen Onlineshop: Schnell verfügbar und jederzeit ausbaubar."
      />

      {/* Pricing cards */}
      <section className="bg-gray-100 pb-8 md:py-8 mt-10">
        <div className="container mx-auto px-2 pt-4 pb-12 text-gray-800">
          <h1 className="w-full my-2 text-3xl md:text-4xl font-bold leading-tight text-center text-gray-800">
            Ihre Investition
          </h1>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>

          <p className="text-center px-4">
            Wir bieten Ihnen drei Zahlungsmodelle für Ihren Onlineshop an. Profitieren Sie von Qualitätsarbeit zum günstigen Preis.
          </p>

          <div className="flex flex-col sm:flex-row justify-center md:pt-12 my-12 sm:my-4">
            <div className="order-2 md:order-1 flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4">
              <div className="flex-1 bg-white text-gray-800 md:text-gray-600 rounded-t rounded-b-none overflow-hidden shadow">
                <div className="p-8 text-3xl font-bold text-center border-b-4">
                  All-In-One (mehrsprachig)
                </div>
                <ul className="w-full text-center text-sm">
                <li className="border-b py-4">Shopsystem,<br/><span className="font-normal"> fixfertig für Sie konfiguriert</span></li>
                  <li className="border-b py-4">
                    Alle Optionen von «All-In-One» <br/> - alles was Sie brauchen
                  </li>
                  <li className="border-b py-4">bis zu 3 Sprachen, <br/><span className="font-normal">optimal für mehrere Sprachregionen</span></li>
                </ul>
              </div>
              <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                <div className="w-full pt-6 text-3xl text-gray-600 font-bold text-center">
                  CHF 2'400 <span className="text-base">einmalig</span>
                </div>
                <div className="flex items-center justify-center">
                  <Link to="/bestellformular/">
                    <button className="mx-auto lg:mx-0 hover:underline bg-gray-600 text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">
                      Bestellen
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="order-1 md:order-2 flex flex-col w-5/6 lg:w-1/3 mx-auto lg:mx-0 rounded-lg bg-white mt-4 sm:-mt-6 shadow-lg z-10">
              <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                <div className="w-full p-8 text-3xl font-bold text-center relative">
                  All-In-One
                  <span className="absolute top-0 right-0 bg-yellow-500 text-white px-2 py-1 text-base rounded font-normal">Empfehlung</span>
                </div>
                <div className="h-1 w-full gradient my-0 py-0 rounded-t"></div>
                <ul className="w-full text-center text-base font-bold">
                  <li className="border-b py-4">Shopsystem,<br/><span className="font-normal">fixfertig für Sie konfiguriert</span></li>
                  <li className="border-b py-4">Analysetool, <br/><span className="font-normal">verbunden mit Ihrem Shop</span></li>
                  <li className="border-b py-4">mit Zahlungsoption Kreditkarte*<br/><span className="font-normal">für alle Visa und Mastercard</span></li>
                  <li className="border-b py-4">
                    Inkl. 1 Jahr Hosting & Updates,<br/><span className="font-normal">wir übernehmen das</span>
                  </li>
                  <li className="border-b py-4">
                    Inkl. 7 Tage kostenlosem Support, <br/><span className="font-normal">per E-Mail (Fair use),<br/> wir sind für Sie da.</span>
                  </li>
                </ul>
              </div>
              <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                <div className="w-full pt-6 text-4xl font-bold text-center">
                  <span className="text-base">nur</span> CHF 1'900 <span className="text-base">einmalig</span>
                </div>
                <div className="flex items-center justify-center">
                  <Link to="/bestellformular/">
                    <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">
                      Bestellen
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="order-3 flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4">
              <div className="flex-1 bg-white text-gray-800 md:text-gray-600 rounded-t rounded-b-none overflow-hidden shadow">
                <div className="p-8 text-3xl font-bold text-center border-b-4">
                  Teilzahlung
                </div>
                <ul className="w-full text-center text-sm">
                <li className="border-b py-4">Shopsystem,<br/><span className="font-normal"> fixfertig für Sie konfiguriert</span></li>
                <li className="border-b py-4">
                    Alle Optionen von «All-In-One» <br/> - alles was Sie brauchen
                  </li>
                  <li className="border-b py-4">Zahlung in Raten,<br/><span>bezahlen Sie bequem monatlich</span></li>
                </ul>
              </div>
              <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                <div className="w-full pt-6 text-3xl text-gray-600 font-bold text-center">
                  CHF 180 <span className="text-base">/ pro Monat**</span>
                </div>
                <div className="flex items-center justify-center">
                  <Link to="/bestellformular/">
                    <button className="mx-auto lg:mx-0 hover:underline bg-gray-600 text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">
                      Bestellen
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <p className="mt-10 text-xs text-center text-gray-600">
            * Bei Kundenzahlungen mit Kreditkarte fallen zusätzlich Transaktionskosten vom Anbieter an
          </p>

          <p className="mt-2 text-xs text-center text-gray-600">
            ** Laufzeit 12 Monate (Total CHF 2160), danach nur noch
            Betriebskosten
          </p>
        </div>
      </section>

      <CtaFooter />
    </Layout>
  );
}

export default Preis;
